import axios, { AxiosError, AxiosResponse } from 'axios';
import InvoiceModel from '../models/InvoiceModel';
import QuoteModel from '../models/QuoteModel';

export interface ResponseQuote {
  status: number;
  data: {
    error: string;
    message: string;
  };
}

const { REACT_APP_API_URL } = process.env;

export const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 15000,
});

const responseBody = (response: AxiosResponse) => response.data.content;
const responseError = (response: AxiosError) => response.response;
const requests = {
  get: (url: string) => instance.get(url).then(responseBody).catch(responseError),
  post: (url: string, typeName: string) =>
    instance.post(url, { typeName }).then(responseBody).catch(responseError),
};

export const Invoice = {
  getInvoice: async (jwtToken: string): Promise<InvoiceModel> =>
    requests.get(`/checkout/${jwtToken}`),
  getQuote: async (jwtToken: string): Promise<QuoteModel> => requests.get(`/approval/${jwtToken}`),
  getPDFQuote: async (jwtToken: string): Promise<string> =>
    requests.get(`/approval/${jwtToken}/pdf`),
  acceptQuote: async (jwtToken: string, typeName: string): Promise<ResponseQuote> =>
    requests.post(`/approval/${jwtToken}/accept`, typeName),
};
