import { FC, Key } from 'react';
import TaxRates from '../TaxRates/TaxRates';
import styles from '../InvoiceDetails/InvoiceDetails.module.css';

interface ItemsProps {
  items: any;
}

const Items: FC<ItemsProps> = props => {
  const { items } = props;
  return (
    <>
      {items.map(
        (item: {
          id: Key;
          description: string;
          amount?: number;
          amountTotal: number;
          taxRates: { displayName: string; percentage: number }[];
        }) => {
          let amount = 0;
          amount = item.amount ? item.amount : item.amountTotal;

          return (
            <div key={item.id + 'b'}>
              <div key={item.id} className={styles.serviceDetailsPanelItem}>
                <span>{item.description}</span>
                <span className={styles.value}>${amount.toFixed(2)}</span>
                <hr
                  className={[
                    'fmc-divider--horizontal',
                    styles.serviceDetaiserviceDetailsPanelDivider,
                  ].join(' ')}></hr>
              </div>
              {item.taxRates && <TaxRates amount={amount} taxRates={item.taxRates} />}
            </div>
          );
        },
      )}
    </>
  );
};

export default Items;
