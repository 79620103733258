import { FC, Key } from 'react';
import styles from '../InvoiceDetails/InvoiceDetails.module.css';

interface ItemsProps {
  taxRates: any;
  amount: number;
}

const taxRates: FC<ItemsProps> = props => {
  const { taxRates, amount } = props;

  function taxValue(amount: number, percentage: number) {
    return amount * (percentage / 100);
  }

  return (
    <>
      {taxRates.map((taxAmount: { displayName: string; percentage: number }, index: Key) => (
        <div key={index + 'a'} className={styles.serviceDetailsPanelItem}>
          <span>{`${taxAmount.displayName} ${taxAmount.percentage}%`}</span>
          <span className={styles.value}>${taxValue(amount, taxAmount.percentage).toFixed(2)}</span>
          <hr
            className={[
              'fmc-divider--horizontal',
              styles.serviceDetaiserviceDetailsPanelDivider,
            ].join(' ')}
          ></hr>
        </div>
      ))}
    </>
  );
};

export default taxRates;
