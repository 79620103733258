import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Approval from './pages/Approval/Approval';
import Checkout from './pages/Checkout/Checkout';
//

function App() {
  useEffect(() => {
    const root = document.documentElement;
    root.setAttribute('data-brand', 'ford');
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/checkout/:jwtFromUrl" element={<Checkout />} />
        <Route path="/approval/:jwtFromUrl" element={<Approval />} />
      </Routes>
    </Router>
  );
}

export default App;
