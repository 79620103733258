import { FMCAccordion } from '@ford/ford-design-system';
import { FC, useEffect, useRef } from 'react';
import { CustomerAddress, Item } from '../../models/InvoiceModel';
import { Item as ItemApproval } from '../../models/QuoteModel';
import InvoiceItems from '../InvoiceItems/InvoiceItems';
import styles from './InvoiceDetails.module.css';
import Items from '../Items/Items';
import c from 'classnames';
import { ColorsRules, ColorsRulesBackGround, DownloadButton, IconAccept } from '../Styles/Style';

interface InvoiceDetailsProps {
  invoicePdfLink: string;
  message: string;
  invoiceAmountDue: number;
  dueDate: string;
  customerAddress: CustomerAddress | null;
  customerName: string;
  invoiceNumber: string;
  items: Item[] | ItemApproval[];
  color: string;
  accept?: boolean;
}

const InvoiceDetails: FC<InvoiceDetailsProps> = props => {
  const {
    invoiceAmountDue,
    invoiceNumber,
    invoicePdfLink,
    message,
    dueDate,
    customerAddress,
    customerName,
    items,
    color,
    accept,
  } = props;

  const accordionRef = useRef<HTMLDivElement>(null);
  const accordion = useRef();

  useEffect(() => {
    if (!accordion.current) {
      const currentAccordion = new FMCAccordion(accordionRef.current);
      accordion.current = currentAccordion;
      currentAccordion.expandAll();
    }
  }, []);

  return (
    <>
      <h1 className={c('fmc-type--subtitle1', styles.title)}>
        <ColorsRules color={color}>{message} </ColorsRules>
        {accept && (
          <IconAccept className="fds-icon fds-icon--24 fds-font--ford-icons__check-filled" />
        )}
      </h1>

      <h3>
        <ColorsRules color={color}>${invoiceAmountDue.toFixed(2)}</ColorsRules>
      </h3>
      <div className={[styles.mobileItems].join(' ')}>
        <InvoiceItems
          dueDate={dueDate}
          customerAddress={customerAddress}
          customerName={customerName}
          invoiceNumber={invoiceNumber}
          color={color}
        />
      </div>
      <div
        ref={accordionRef}
        className={[
          'js-fmc-accordion',
          'fmc-accordion',
          'fds-color__bg--light',
          'fmc-accordion--light',
          styles.serviceDetailsPanel,
        ].join(' ')}>
        <div className="fmc-accordion__panel fmc-accordion__panel--expanded">
          <button
            type="button"
            className={['fmc-accordion__button', styles.serviceDetailsPanelTitle].join(' ')}
            id="panel-1-button"
            aria-controls="panel-1-content">
            <span className={['fmc-accordion__button-title'].join(' ')}>
              <ColorsRules color={color}>Service Details</ColorsRules>
            </span>
            <span className="fmc-accordion__button-expand fds-icon fds-color__text--primary"></span>
          </button>
          <div className="fmc-accordion__body">
            <div
              className="fmc-accordion__content fds-color__bg--white fds-color__text--gray2"
              id="panel-1-content"
              role="region"
              aria-hidden="false">
              <div className="fmc-type--body1">
                <Items items={items} />
                <div className={styles.serviceDetailsTotal}>
                  <span>
                    <ColorsRules color={color}>Total</ColorsRules>
                  </span>
                  <span className={styles.value}>
                    <ColorsRules color={color}>${invoiceAmountDue.toFixed(2)}</ColorsRules>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownloadButton>
        <button
          data-testid="downloadPdf"
          onClick={() => window.open(invoicePdfLink)}
          className="fmc-text-button fds-m--t-2">
          <ColorsRulesBackGround color={color}>
            <span className={['fds-icon fds-icon--offset-left'].join(' ')}></span>
          </ColorsRulesBackGround>
          <ColorsRules color={color}>Download PDF</ColorsRules>
        </button>
      </DownloadButton>
    </>
  );
};

export default InvoiceDetails;
